<template>
  <div class="chat py-4">
    <div class="max-width">
      <v-row>
        <!-- users -->
        <v-col cols="12" md="4" class="px-0 pb-0 white radius-15">
          <div class="chat-content">
            <div
              class="head-section d-flex align-center justify-space-between py-2 px-5"
            >
              <span class="black--text font-20">{{$t('dashboard.chats')}}</span>
            </div>
            <div v-if="messages.length > 0" class="px-3">
              <div
                class="px-2 py-2 radius-5 cursor-pointer chat-style"
                @click="getSelectedMessages(chat, true)"
                :class="chat.id == paramID ? 'selected-chat' : ''"
                v-for="(chat, i) in messages"
                :key="i"
              >
                <v-row align="center" class="bluebgdash">
                  <v-col cols="2" class="pa-2">
                    <div class="user-avatar-mini mx-auto d-flex align-center">
                      <img
                        width="50px"
                        height="50px"
                        class="object-contine circle"
                        :src="require('@/assets/images/general/hospital.jpg')"
                        alt=""
                      />
                    </div>
                  </v-col>
                  <v-col cols="10" class="pa-2">
                    <div class="d-flex align-start justify-space-between">
                      <span class="font-20 font-500 darkbord--text d-block">{{
                        chat.hospital_name
                      }}</span>
                    </div>
                    <span class="font-16 greytext--text d-block">{{
                      chat.message
                    }}</span>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="text-center mt-4" v-else>
              <span class="primary--text font-22 font-500">{{$t('dashboard.noChat')}}</span>
            </div>
          </div>
        </v-col>
        <v-col md="8" cols="12">
          <div class="white radius-6" v-if="chatMessages.length > 0">
            <!-- chat header -->
            <div class="pa-2">

            <div class="bluebgdash px-2 py-2 d-flex radius-10-trl align-center">
              <div
                class="chat-user-cover white radius-15 mx-4 d-flex align-center justify-center"
              >
                <img
                  class="object-cover radius-15 mx-auto"
                  width="50px"
                  height="50px"
                  :src="require('@/assets/images/general/hospital.jpg')"
                  alt=""
                />
              </div>
              <span class="font-26 darkprimary--text font-600">{{
                selectedChat.hospital_name
              }}</span>
            </div>
            </div>

            <!-- chat messages -->
            <div class="chat-content px-2 py-2 bluebgdash py-4 mt-1" id="messages">
              <div>
                <div
                  class="chat-content__message py-2 px-4 radius-5 mb-4"
                  v-for="(message, i) in chatMessages"
                  :key="i"
                  :class="
                    message.type == 1 ? 'white' : 'register-primary ml-auto'
                  "
                >
                  <span
                    :class="message.type == 1 ? 'black--text' : 'white--text'"
                    >{{ message.message }}</span
                  >
                  <div class="d-flex justify-end">
                    <span
                      class="d-block font-16"
                      :class="
                        message.type == 1
                          ? 'greytext--text'
                          : 'lightgreen--text'
                      "
                      >{{ message.time }}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- chat new message -->
            <div class="white px-2 py-2 mt-4">
              <v-text-field
                v-model="msg"
                outlined
                :placeholder="$t('forms.typeMessage')"
                type="text"
                height="50px"
                :hide-details="true"
                dense
                color="white"
                @keypress.enter="sendMessage"
              >
                <template v-slot:append>
                  <div
                    class="w-100 h-100 cursor-pointer d-flex align-center justify-center"
                    @click="sendMessage"
                  >
                    <v-icon color="white">mdi-send</v-icon>
                  </div>
                </template>
              </v-text-field>
            </div>
          </div>

          <!-- chat no messages selected -->
          <div
            class="h-100 d-flex align-center justify-center"
            v-if="chatMessages.length == 0"
          >
            <span class="register-primary--text font-500 font-32">{{
              $t("dashboard.selectChat")
            }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    doctorInfo: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    messages: [],
    chatMessages: [],
    doctorInfo: {},
    selectedChat: {},
    msg: "",
  }),
  computed: {
    ...mapGetters(["profile"]),
    role() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    async getSelectedMessages(user) {
      this.selectedChat = user;
      let { data } = await this.$axios.post("get_messages", {
        hospital_id: user.hospital_id,
      });
      if (data) {
        this.chatMessages = data.data.reverse();
        setTimeout(() => {
          var objDiv = document.getElementById("messages");
          objDiv.scrollTop = objDiv.scrollHeight;
        }, 1);
      }
    },
    async sendMessage() {
      let obj = {
        type: localStorage.getItem("role") == "doctor" ? "doctor" : "hospital",
        doctor_id: this.profile.id,
        hospital_id: this.selectedChat.hospital_id,
        message: this.msg,
      };

      let { data } = await this.$axios.post("send_doctor_message", obj, {
        headers: {
          Accept: "application/json",
        },
      });
      if (data) {
        this.$store.dispatch("showSnack", {
          text: "Sent Successfully",
          color: "success",
        });
        this.chatMessages.push({
          message: this.msg,
          type: localStorage.getItem("role") == "doctor" ? 0 : 1,
        });
        await this.getSelectedMessages(this.selectedChat);
        setTimeout(() => {
          var objDiv = document.getElementById("messages");
          objDiv.scrollTop = objDiv.scrollHeight;
        }, 1);
        this.msg = "";
      } else {
        this.$store.dispatch("showSnack", {
          text: "Wrong",
          color: "error",
        });
      }
    },
    async getMessages() {
      let { data } = await this.$axios.get("all/chats");
      if (data) {
        this.messages = data.data.reverse();
        setTimeout(() => {
          var objDiv = document.getElementById("messages");
          objDiv.scrollTop = objDiv.scrollHeight;
        }, 1);
      }
    },
  },
  async created() {
    await this.getMessages();
  },
};
</script>

<style lang="scss">
.chat {
  .max-width {
    padding: 0px;
  }
  .chat-user-cover {
    padding: 3px 3px 3px 4px !important;
  }
  .chat-content {
    height: calc(100vh - 35vh);
    overflow-y: auto;

    &__message {
      max-width: 500px;
      width: max-content;
    }
  }
  .v-input__append-inner {
    width: 50px !important;
    height: 100%;
    background: $register-primary;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px !important;
    i {
      color: white;
    }
  }
  .v-input {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
  }
  .v-input__slot {
    padding-right: 0px !important;
    fieldset {
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
